import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import db from "../../database";
import { isEmpty } from "lodash";

import VerticalNavigation from "../Header/VerticalNavigation";
import Loader from "../common/Loader";
import styles from "./home.module.css";
// import HomePic from "../../assets/headshot_expanded.png";
import HomePicVert from "../../assets/headshot_expanded2.png";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Soundcloud } from "../../assets/icons/soundcloud-logo.svg";
import "react-lazy-load-image-component/src/effects/blur.css";

const Home = () => {
  const mounted = useRef();
  const [loading, setLoading] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [fade, setFade] = useState("fade-in");
  const history = useHistory();

  const fetchUpcomingEvents = async () => {
    const upcomingEventsRef = query(
      ref(db, `home/upcomingEvents`),
      orderByChild("published"),
      equalTo(true)
    );
    await onValue(upcomingEventsRef, (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        setUpcomingEvents(data);
      } else {
        const isArray = Array.isArray(data);
        const dataArr = isArray ? data : Object.values(data);
        const filteredData = dataArr.filter((item) => !!item);
        setUpcomingEvents(filteredData);
        if (filteredData.length) {
          setEvent(filteredData[0]);
        }
      }
      setLoading(false);
      return data;
    });
  };

  useEffect(() => {
    if (!mounted.current) {
      setLoading(true);
      fetchUpcomingEvents();
      mounted.current = true;
    } else {
      // component did update
      if (event && !loading && upcomingEvents && upcomingEvents.length > 1) {
        const interval = setInterval(() => {
          setFade("");
          setTimeout(() => {
            const currentEventId = event.id;
            const isLastEvent = upcomingEvents.at(-1).id === currentEventId;
            const nextEvent = isLastEvent
              ? upcomingEvents[0]
              : upcomingEvents[
                  upcomingEvents.findIndex(
                    (item) => item.id === currentEventId
                  ) + 1
                ];
            setEvent(nextEvent);
            setFade("fade-in");
          }, 250);
        }, 3000);
        return () => clearInterval(interval);
      }
    }
  }, [loading, event, upcomingEvents]);

  return (
    <>
      <div className={`container-fluid pe-0 ${styles["home-container"]}`}>
        <div className="grid gap-0 vh-100">
          <div
            className={`${styles.box} g-col-4 g-col-md-5 g-col-lg-4 g-col-xl-3 position-relative px-2 ps-md-4`}
          >
            <h1 className={`d-block text-nowrap ${styles["title-400"]}`}>
              Juan Carlos
              <br />
              <span className={styles["title-700"]}>Aliaga Del Bosque</span>
            </h1>
            {/* <h1 className={`d-block d-md-none ${styles["title-400"]}`}>
              Juan Carlos
              <br />
              <span className={styles["title-700"]}>Aliaga Del Bosque</span>
            </h1> */}
            <h6 className="mt-4 text-nowrap">Composer &#183; Violinist</h6>
            <hr className="mt-4" />
            {loading && <Loader />}
            {!isEmpty(upcomingEvents) && (
              <>
                <h6>News &#38; Upcoming Events</h6>
                {upcomingEvents === null && (
                  <span className="text-nowrap">No upcoming events.</span>
                )}
                {!!upcomingEvents && event && (
                  <p
                    className={`${styles.events} ${styles[fade]} text-nowrap`}
                    onClick={() => {
                      let path =
                        event.type === "news"
                          ? `/news?id=${event.relatedId}`
                          : `/projects/${event.relatedId}`;
                      history.push(path, { fromHome: true });
                    }}
                  >
                    <span>{event.title}</span>{" "}
                    {event.date && <span>&#183; {event.date}</span>}
                  </p>
                )}
              </>
            )}
            <div className="d-flex flex-row mt-5">
              <a
                href="https://www.youtube.com/channel/UCNJECebprjlKhx8FrOA3hzA"
                target="_blank"
                rel="noreferrer"
              >
                <Youtube className={styles["social-icon"]} />
              </a>
              <a
                href="https://soundcloud.com/jcadb"
                target="_blank"
                rel="noreferrer"
              >
                <Soundcloud className={styles["social-icon"]} />
              </a>
            </div>
            <span className={`${styles.copyright} small position-absolute`}>
              © {new Date().getFullYear()} All rights reserved.
            </span>
          </div>
          <div className="g-col-8 g-col-md-7 g-col-lg-8 g-col-xl-9 overflow-hidden d-flex position-relative">
            <div className={`${styles["overlay"]}`}></div>
            {/* <LazyLoadImage
              alt="Juan Carlos Aliaga Del Bosque"
              src={HomePic} // use normal <img> attributes as props
              width={"100%"}
              effect="blur"
              wrapperClassName="d-none d-md-flex"
              className={`${styles["home-img"]}`}
            /> */}
            <LazyLoadImage
              alt="Juan Carlos Aliaga Del Bosque"
              src={HomePicVert} // use normal <img> attributes as props
              width={"100%"}
              effect="blur"
              className={`${styles["home-img"]}`}
            />
            <p
              className={`${styles["home-img-author"]} text-end small mt-2 position-absolute text-light bottom-0 end-0 mx-4 mt-n03`}
            >
              ph: Cherry Lowrie
            </p>
          </div>
          <div
            className={`position-absolute g-col-sm-2 py-5 d-none d-lg-flex h-100 end-0`}
          >
            <VerticalNavigation transparent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
