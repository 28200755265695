import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Sidebar from "./Sidebar";
import styles from "./header.module.css";
import MenuIcon from "../../assets/icons/menu-light.svg";
import MenuIconDark from "../../assets/icons/menu-dark.svg";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;
  const isHome = currentPath === "/";
  const toggleSidebar = (toggleValue) => {
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];
    if (sidebarOpen) {
      body.classList.remove("no-scroll");
      html.classList.remove("no-scroll");
    } else {
      body.classList.add("no-scroll");
      html.classList.add("no-scroll");
    }
    setSidebarOpen(toggleValue);
  };
  return (
    <nav
      id={styles["header-nav"]}
      className={`navbar w-100 py-2 ${
        isHome ? `position-absolute ${styles["header-home"]}` : ""
      }`}
    >
      <div
        className={`container-fluid justify-content-${
          isHome ? "end" : "between"
        }`}
      >
        {!isHome && (
          <h6 className="m-0 text-left" onClick={() => history.push("/")}>
            <span className={styles.name}>Juan Carlos&nbsp;</span>Aliaga Del
            Bosque
          </h6>
        )}
        <button
          className={`d-box ${isHome ? "d-lg-none" : ""} ${styles["menu-btn"]}`}
        >
          <img
            alt="Menu"
            src={isHome ? MenuIconDark : MenuIcon}
            onClick={() => toggleSidebar(!sidebarOpen)}
          />
        </button>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={toggleSidebar} />
      </div>
    </nav>
  );
};

export default Header;
