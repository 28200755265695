import React from 'react';
import './Player.css';

const AudioAnimation = () => (
    <div className="audio-animation d-flex justify-content-around align-items-end">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
);

export default AudioAnimation;
