import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import PageContainer from "../common/PageContainer";
import PageTitle from "../common/PageTitle";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Soundcloud } from "../../assets/icons/soundcloud-logo.svg";
import ContactPic from "../../assets/Aliaga_Contact.jpg";
import styles from "./contact.module.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const Contact = () => (
  <PageContainer>
    <PageTitle title="Get in touch" />
    <div className="row">
      <div className="col-12 col-md-5">
        <LazyLoadImage
          alt="Juan Carlos Aliaga Del Bosque"
          src={ContactPic}
          width={"100%"}
          effect="blur"
        />
        <p className="text-end text-muted small mt-2">ph: Cherry Lowrie</p>
      </div>
      <div className="col-12 col-md-6 pt-5">
        <a
          className={`d-block mb-2 ${styles.link}`}
          href="mailto:jcaliagadb@gmail.com"
        >
          jcaliagadb@gmail.com
        </a>
        <hr className="my-4" />
        <a
          href="https://www.youtube.com/channel/UCNJECebprjlKhx8FrOA3hzA"
          target="_blank"
          rel="noreferrer"
        >
          <Youtube className={styles["social-icon"]} />
        </a>
        <a href="https://soundcloud.com/jcadb" target="_blank" rel="noreferrer">
          <Soundcloud className={styles["social-icon"]} />
        </a>
      </div>
    </div>
  </PageContainer>
);

export default Contact;
