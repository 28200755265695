import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState, useRef } from "react";

import Header from "components/Header";
import Home from "components/Home";
import About from "components/About";
import News from "components/News";
import Projects from "components/Projects";
import Works from "components/Works";
import Player from "components/Player";
import Contact from "components/Contact";

import "./App.css";
import "css/custom.css";

const App = () => {
  // State for player
  const [playing, setPlaying] = useState(false);
  const [showPlayer, setShowPlayer] = useState(true);
  const [currentTrackID, setCurrentTrackID] = useState(null);
  const playerRef = useRef();

  const selectTrack = (trackID) => {
    if (!trackID.includes(playerRef.current.state.trackID)) {
      playerRef.current.selectThisTrack(trackID[0]);
    } else {
      playerRef.current.onPlayPauseClickHandler();
    }
  };

  const pauseSelectedTrack = () => {
    playerRef.current.onPlayPauseClickHandler();
  };

  const togglePlayer = () => {
    const playerElement = document.getElementById("musicPlayer");
    if (playerRef.current._isMounted && playerElement) {
      // Cerrar reproductor
      if (showPlayer) {
        playerElement.classList.add("closed");
        // Si el audio se esta reproduciendo, pausar
        if (playerRef.current.state.playing) {
          // console.log('pausar al cerrar')
          playerRef.current.onPlayPauseClickHandler();
        }
        // Mostrar
      } else {
        playerElement.classList.remove("closed");
      }
      setShowPlayer(!showPlayer);
    }
  };

  return (
    <Router>
      <div className="App">
        {/* Compartido entre componentes */}
        <Header />
        {/* Player */}
        <Player
          ref={playerRef}
          setPlaying={setPlaying}
          setCurrentTrackID={setCurrentTrackID}
          setShowPlayer={togglePlayer}
        />
        {/* Renderizado por rutas */}
        <Switch>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/works">
            <Works
              playing={playing}
              currentTrackID={currentTrackID}
              selectTrack={selectTrack}
              pauseSelectedTrack={pauseSelectedTrack}
              showPlayer={showPlayer}
              setShowPlayer={togglePlayer}
            />
          </Route>
          <Route path="/projects/:projectId">
            <Projects />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          {/* <Redirect from="/projects" to="/projects/id" /> */}
          <Route path="/news">
            <News />
          </Route>
          <Route path="/">
            {/* <p>Loading Home</p> */}
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
