// import Rebase from 're-base';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

var app = initializeApp({
  apiKey: "AIzaSyAFWia_SoBPzIJaDT_RTgik1YBWj4MUnnc",
  authDomain: "aliagadelbosque.firebaseapp.com",
  databaseURL: "https://aliagadelbosque-default-rtdb.firebaseio.com",
  projectId: "aliagadelbosque",
  storageBucket: "aliagadelbosque.appspot.com",
  messagingSenderId: "1038884450546",
  appId: "1:1038884450546:web:613cc46203b56b812de06b",
  measurementId: "G-D4BJGGZPBR"
});

var db = getDatabase(app);

export default db;