import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './sidebar.module.css';

const pages = [
    { title: 'Home', path: '/' },
    { title: 'About', path: '/about' },
    { title: 'Works', path: '/works'},
    { title: 'News', path: '/news' },
    { title: 'Projects', path: '/projects' },
    { title: 'Contact', path: '/contact'}
];

const VerticalNavigation = ({ setSidebarOpen, transparent = false }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const isHome = currentPath === '/';
    return (
    <ul className={`${styles['sidebar-list']} ${transparent ? styles['sidebar-transparent'] : ''} py-4 pe-5 w-100 text-end align-self-center mb-0`}>
        {pages.map(page => (
            <li
                className={`${styles['sidebar-link']} ${page.path === currentPath ? styles['active-link'] : ''} ${isHome && page.path === '/' ? 'd-lg-none' : ''} my-4`}
                key={`${page.title}-link`}
            >
                <Link
                    className="d-block w-100"
                    onClick={() => {
                        if (setSidebarOpen) {
                            setSidebarOpen(false);
                        }
                    }}
                    to={page.path}
                >
                    {page.title}
                </Link>
            </li>
        ))}
    </ul>
)};

export default VerticalNavigation;
