export const GENRES = [
  {
    value: "solo",
    label: "Solo",
  },
  {
    value: "voice",
    label: "Voice",
  },
  {
    value: "chamber",
    label: "Chamber",
  },
  {
    value: "choir",
    label: "Choir",
  },
  {
    value: "soloorch",
    label: "Solo orchestra",
  },
  {
    value: "orch",
    label: "Orchestra",
  },
];

const constants = {
  GENRES,
};

export default constants;
