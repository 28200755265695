import React from 'react';

const Loader = () => (
    <div className='loader'>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
    </div>
);

export default Loader;
