import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { render } from "react-dom";
import gfm from "remark-gfm";

const ProjectRender = ({ title, content }) => {
  useEffect(() => {
    render(
      <>
        <h1>{title}</h1>
        <ReactMarkdown
          remarkPlugins={[gfm]}
          children={content.replaceAll("\\n", "\n")}
        />
      </>,
      document.querySelector("#project-content")
    );
  }, [content, title]);

  return null;
};

export default ProjectRender;
