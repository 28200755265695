import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FsLightbox from "fslightbox-react";
import { ref, onValue } from "firebase/database";
import db from "../../database";
import { getMutlipleDownloadURLs } from "services";

import styles from "./about.module.css";
import BioPic from "../../assets/Aliaga_Bio.jpg";
import "react-lazy-load-image-component/src/effects/blur.css";

const About = () => {
  const [bioText, setBioText] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [toggler, setToggler] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);

  const fetchBio = () => {
    const bioRef = ref(db, `/about/bio`);
    onValue(bioRef, async (snapshot) => {
      const data = await snapshot.val();
      setBioText(data);
    });
  };

  const getContent = (item) =>
    item.split("\\n").map((meti, windex) => (
      <p key={`${item.title}-${windex}`}>
        {meti.match(/[^\]]+(?![^<]*\])/g)}
        <a
          target="_blank"
          rel="noreferrer"
          href={meti.match(/(?<=\[)(.*?)(?=\])/g)}
        >
          {meti.match(/(?<=<)(.*?)(?=>)/g)}
        </a>
      </p>
    ));

  useEffect(() => {
    fetchBio();
    getMutlipleDownloadURLs("images/about").then((res) => {
      const urls = res.map((item) => {
        const regex = new RegExp(/.+?(?=&token)/);
        return item.match(regex)[0];
      });
      setGalleryImages(urls);
    });
  }, []);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
          {getContent(bioText)}
        </div>
        <div className="col-12 col-md-6 pt-4 pt-lg-0">
          <div className="w-75 mx-auto">
            <LazyLoadImage
              alt="Juan Carlos Aliaga Del Bosque"
              src={BioPic} // use normal <img> attributes as props
              width={"100%"}
              effect="blur"
              className={styles["bio-pic"]}
            />
            <p className="text-end text-muted small mt-2">ph: Cherry Lowrie</p>
          </div>
        </div>
      </div>
      {/* Image grid */}
      {console.log(toggler)}
      <div className="grid gap-1 row-gap-1">
        {galleryImages.map((image, index) => (
          <figure
            className={`${
              index > 0 && index % 3 === 0
                ? "g-col-6"
                : index > 0 && index % 4 === 0
                ? "g-col-6"
                : "g-col-4"
            } ${styles["gallery-item"]}`}
            onClick={() => {
              setImageIndex(index);
              setToggler(!toggler);
            }}
          >
            <img
              className={`w-100 h-100 object-fit-cover ${styles["gallery-img"]}`}
              src={image}
              alt=""
            />
          </figure>
        ))}
      </div>
      <FsLightbox
        toggler={toggler}
        sources={galleryImages}
        sourceIndex={imageIndex}
        type="image"
      />
    </div>
  );
};

export default About;
