import React, { Component } from "react";
import "./Player.css";

import TracksList from "./TracksList";
// import AudioAnimation from './AudioAnimation';
import { playlist } from "../../utils/data";
import CloseIcon from "../../assets/icons/close-white.svg";
import ListIcon from "../../assets/icons/list.svg";

// eslint-disable-next-line
String.prototype.toHHMMSS = function () {
  // snippet taken from stackoverflow
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (hours > 0 ? hours + ":" : "") + minutes + ":" + seconds;
};

class Player extends Component {
  _isMounted = false;

  state = {
    isMounted: false,
    expandedPlayer: false,
    tracksList: playlist,
    currentTrackIndex: 0,
    trackID: null,
    trackURL: "",
    trackTitle: "",
    player: null,
    startPlaying: false,
    totalDuration: 0,
    currentTime: 0,
    progressSpanTime: 0,
    loop: false,
    shuffle: false,
    sidebar: false,
    playing: false,
    volume: 0.5,
    trackLoaded: false,
    progressHandleActive: false,
    progressHandleCtrStyleLeft: "0%",
    doneProgressBarStyleRight: "100%",
    volumeHandleActive: false,
    volumeHandleCtrStyleLeft: "0%",
    doneVolumeBarStyleRight: "100%",
    showfavsonly: false,
    searchString: "",
  };

  updateSearchString = (event) => {
    this.setState({ searchString: event.target.value });
  };

  toggleSidebar = () => {
    // const body = document.getElementsByTagName('body')[0];
    // const html = document.getElementsByTagName('html')[0];
    // if (this.state.sidebar) {
    //     body.classList.remove('no-scroll');
    //     html.classList.remove('no-scroll');
    // } else {
    //     body.classList.add('no-scroll');
    //     html.classList.add('no-scroll');
    // }
    this.setState({ sidebar: !this.state.sidebar });
  };

  toggleLoop = () => {
    const player = this.state.player;
    player.loop = !this.state.loop;
    this.setState({ player: player, loop: !this.state.loop });
  };

  toggleShuffle = () => {
    this.setState({ shuffle: !this.state.shuffle });
  };

  toggleFavourite = () => {
    this.setState({ isFavourite: !this.state.isFavourite });
    this.state.tracksList.map((track) => {
      if (track.id === this.state.trackID) {
        track.isFavourite = !track.isFavourite;
      }
      return track;
    });
  };

  toggleShowfavsonly = () => {
    this.setState({ showfavsonly: !this.state.showfavsonly });
  };

  playNextTrack = () => {
    let currentTrackIndex = this.state.currentTrackIndex;
    if (!this.state.shuffle) {
      currentTrackIndex += 1;
      if (currentTrackIndex >= this.state.tracksList.length) {
        currentTrackIndex = 0;
      }
    } else {
      currentTrackIndex = Math.floor(
        Math.random() * this.state.tracksList.length + 0
      );
    }
    this.setState(
      { currentTrackIndex: currentTrackIndex },
      this.loadTrackIntoState
    );
  };

  playPrevTrack = () => {
    let currentTrackIndex = this.state.currentTrackIndex;
    if (!this.state.shuffle) {
      currentTrackIndex -= 1;
      if (currentTrackIndex < 0) {
        currentTrackIndex = this.state.tracksList.length - 1;
      }
    } else {
      currentTrackIndex = Math.floor(
        Math.random() * this.state.tracksList.length + 0
      );
    }
    this.setState(
      { currentTrackIndex: currentTrackIndex },
      this.loadTrackIntoState
    );
  };

  selectThisTrack = (trackID) => {
    let currentTrackIndex = 0;
    this.state.tracksList.map((track, index) => {
      if (track.id === trackID) {
        currentTrackIndex = index;
      }
      return track;
    });
    this.setState(
      {
        currentTrackIndex: currentTrackIndex,
      },
      () => {
        this.loadTrackIntoState();
      }
    );
    return false;
  };

  updatePlayingInfo = () => {
    const player = this.state.player;
    const totalDuration = parseInt(player.duration, 10).toString().toHHMMSS();
    const currentTime = parseInt(player.currentTime, 10).toString().toHHMMSS();
    this.setState(
      {
        totalDuration: totalDuration,
        currentTime: currentTime,
        progressSpanTime: !this.state.progressHandleActive
          ? currentTime
          : this.state.progressSpanTime,
      },
      () => {
        this.updateProgressBar();
      }
    );
  };

  updateProgressBar = () => {
    if (
      document.getElementById("progressHandleCtr") &&
      document.getElementById("progress-bar")
    ) {
      // const progressHandleCtrParent = document.getElementById("progressHandleCtr").parentElement;
      const progressHandleCtrParent = document.getElementById("progress-bar");
      const progressHandleCtr = document.getElementById("progressHandleCtr");

      if (
        progressHandleCtrParent !== undefined &&
        progressHandleCtr !== undefined
      ) {
        const progressHandleCtrParentWidth = parseFloat(
          window.getComputedStyle(progressHandleCtrParent, null).width
        );
        const totalTimePtg =
          parseFloat(
            this.state.player.currentTime / this.state.player.duration
          ) * parseFloat(progressHandleCtrParentWidth);

        this.setState({
          progressHandleCtrStyleLeft: !this.state.progressHandleActive
            ? totalTimePtg + "px"
            : this.state.progressHandleCtrStyleLeft,
          doneProgressBarStyleRight:
            progressHandleCtrParentWidth - totalTimePtg + "px",
        });
      }
    }
  };

  playPauseAudio = () => {
    if (this.state.playing) {
      // this.state.player.play();
      const playPromise = this.state.player.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played");
            // setPlaying(!this.state.playing);
          })
          .catch((error) => {
            console.error(error);
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    } else {
      const pausePromise = this.state.player.pause();
      if (pausePromise !== undefined) {
        pausePromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            // console.log("audio paused");
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            // console.log("pause prevented");
          });
      }
    }
  };

  onPlayPauseClickHandler = (event) => {
    const { setPlaying } = this.props;
    const value = !this.state.playing;
    this.setState(
      {
        startPlaying: value,
        playing: value,
      },
      () => {
        this.startPlaying();
        setPlaying(value);
      }
    );
  };

  touchPositionFst = 0;
  spannedTime = 0;
  volume = 0;

  progBarPickHandler = (event) => {
    if (event.type === "mousedown") {
      this.touchPositionFst = event.pageX;
      document.addEventListener("mousemove", this.progBarMoveHandler);
      document.addEventListener("mouseup", this.progBarDropHandler);
    } else if (event.type === "touchstart") {
      this.touchPositionFst = event.touches[0].pageX;
      event.target.addEventListener("touchmove", this.progBarMoveHandler);
      event.target.addEventListener("touchend", this.progBarDropHandler);
    }

    const handleCtrParent = document.getElementById("progress-bar");
    if (handleCtrParent) {
      const progressBarWidth = parseFloat(
        window.getComputedStyle(handleCtrParent, null).width
      );

      let handleCtrStyleLeft =
        this.touchPositionFst -
        handleCtrParent.clientLeft -
        parseFloat(
          window.getComputedStyle(
            document.getElementsByClassName("Player")[0],
            null
          ).paddingLeft
        ) +
        "px";

      if (parseFloat(handleCtrStyleLeft) < 0) {
        handleCtrStyleLeft = "0px";
      } else if (parseFloat(handleCtrStyleLeft) > progressBarWidth) {
        handleCtrStyleLeft = progressBarWidth + "px";
      }

      this.spannedTime =
        parseFloat(this.state.player.duration / progressBarWidth) *
        parseFloat(handleCtrStyleLeft);

      this.setState({
        progressHandleActive: true,
        progressHandleCtrStyleLeft: handleCtrStyleLeft,
        progressSpanTime: this.spannedTime.toString().toHHMMSS(),
      });
    }
  };

  progBarMoveHandler = (event) => {
    let touchPositionSnd = 0;
    if (event.type === "mousemove") {
      touchPositionSnd = event.pageX;
      document.addEventListener("mouseup", this.progBarDropHandler);
    } else if (event.type === "touchmove") {
      touchPositionSnd = event.touches[0].pageX;
      event.target.addEventListener("touchend", this.progBarDropHandler);
    }

    const handleCtrParent = document.getElementById("progress-bar");
    if (handleCtrParent) {
      const progressBarWidth = parseFloat(
        window.getComputedStyle(handleCtrParent, null).width
      );

      let calcPosition = touchPositionSnd - this.touchPositionFst;
      this.touchPositionFst = touchPositionSnd;

      let handleCtrStyleLeft =
        parseFloat(this.state.progressHandleCtrStyleLeft) + calcPosition + "px";

      if (parseFloat(handleCtrStyleLeft) < 0) {
        handleCtrStyleLeft = "0px";
      } else if (parseFloat(handleCtrStyleLeft) > progressBarWidth) {
        handleCtrStyleLeft = progressBarWidth + "px";
      }

      this.spannedTime =
        parseFloat(this.state.player.duration / progressBarWidth) *
        parseFloat(handleCtrStyleLeft);

      this.setState({
        progressHandleCtrStyleLeft: handleCtrStyleLeft,
        progressSpanTime: this.spannedTime.toString().toHHMMSS(),
      });
    }
  };

  progBarDropHandler = (event) => {
    const player = this.state.player;
    player.currentTime = this.spannedTime;
    this.setState(
      {
        player: player,
        progressHandleActive: false,
        currentTime: this.spannedTime.toString().toHHMMSS(),
        progressSpanTime: this.spannedTime.toString().toHHMMSS(),
      },
      () => {
        event.target.removeEventListener("touchmove", this.progBarMoveHandler);
        event.target.removeEventListener("touchend", this.progBarDropHandler);
        document.removeEventListener("mousemove", this.progBarMoveHandler);
        document.removeEventListener("mouseup", this.progBarDropHandler);
      }
    );
  };

  volumeBarPickHandler = (event) => {
    if (event.type === "mousedown") {
      this.touchPositionFst = event.pageX;
      document.addEventListener("mousemove", this.volumeBarMoveHandler);
      document.addEventListener("mouseup", this.volumeBarDropHandler);
    } else if (event.type === "touchstart") {
      this.touchPositionFst = event.touches[0].pageX;
      event.target.addEventListener("touchmove", this.volumeBarMoveHandler);
      event.target.addEventListener("touchend", this.volumeBarDropHandler);
    }

    const volumeBarWidth = parseFloat(
      window.getComputedStyle(document.getElementById("volumeBarCtr"), null)
        .width
    );

    let handleCtrStyleLeft =
      parseFloat(
        this.touchPositionFst - (window.innerWidth - volumeBarWidth) / 2
      ) + "px";
    let doneVolumeBarStyleRight =
      volumeBarWidth - parseFloat(handleCtrStyleLeft) + "px";

    if (parseFloat(handleCtrStyleLeft) < 0) {
      handleCtrStyleLeft = "0px";
    } else if (parseFloat(handleCtrStyleLeft) > volumeBarWidth) {
      handleCtrStyleLeft = volumeBarWidth + "px";
    }
    if (parseFloat(doneVolumeBarStyleRight) < 0) {
      doneVolumeBarStyleRight = "0px";
    } else if (parseFloat(doneVolumeBarStyleRight) > volumeBarWidth) {
      doneVolumeBarStyleRight = volumeBarWidth + "px";
    }

    this.volume = (
      parseFloat(1.0 / volumeBarWidth) * parseFloat(handleCtrStyleLeft)
    ).toFixed(2);

    const player = this.state.player;
    player.volume = this.volume;
    this.setState({
      volumeHandleActive: true,
      player: player,
      volumeHandleCtrStyleLeft: handleCtrStyleLeft,
      doneVolumeBarStyleRight: doneVolumeBarStyleRight,
      volume: this.volume,
    });
  };

  volumeBarMoveHandler = (event) => {
    let touchPositionSnd = 0;
    if (event.type === "mousemove") {
      touchPositionSnd = event.pageX;
      document.addEventListener("mouseup", this.volumeBarDropHandler);
    } else if (event.type === "touchmove") {
      touchPositionSnd = event.touches[0].pageX;
      event.target.addEventListener("touchend", this.volumeBarDropHandler);
    }

    const volumeBarWidth = parseFloat(
      window.getComputedStyle(document.getElementById("volumeBarCtr"), null)
        .width
    );

    let calcPosition = touchPositionSnd - this.touchPositionFst;
    this.touchPositionFst = touchPositionSnd;

    let handleCtrStyleLeft =
      parseFloat(this.state.volumeHandleCtrStyleLeft) + calcPosition + "px";
    let doneVolumeBarStyleRight =
      volumeBarWidth -
      (parseFloat(this.state.volumeHandleCtrStyleLeft) + calcPosition) +
      "px";

    if (parseFloat(handleCtrStyleLeft) < 0) {
      handleCtrStyleLeft = "0px";
    } else if (parseFloat(handleCtrStyleLeft) > volumeBarWidth) {
      handleCtrStyleLeft = volumeBarWidth + "px";
    }
    if (parseFloat(doneVolumeBarStyleRight) < 0) {
      doneVolumeBarStyleRight = "0px";
    } else if (parseFloat(doneVolumeBarStyleRight) > volumeBarWidth) {
      doneVolumeBarStyleRight = volumeBarWidth + "px";
    }

    this.volume = (
      parseFloat(1.0 / volumeBarWidth) * parseFloat(handleCtrStyleLeft)
    ).toFixed(2);

    const player = this.state.player;
    player.volume = this.volume;
    this.setState({
      player: player,
      volumeHandleCtrStyleLeft: handleCtrStyleLeft,
      doneVolumeBarStyleRight: doneVolumeBarStyleRight,
      volume: this.volume,
    });
  };

  volumeBarDropHandler = (event) => {
    this.setState(
      {
        volumeHandleActive: false,
      },
      () => {
        event.target.removeEventListener(
          "touchmove",
          this.volumeBarMoveHandler
        );
        event.target.removeEventListener("touchend", this.volumeBarDropHandler);
        document.removeEventListener("mousemove", this.volumeBarMoveHandler);
        document.removeEventListener("mouseup", this.volumeBarDropHandler);
      }
    );
  };

  loadTrackIntoState = (autoplay = true) => {
    const { setPlaying, setCurrentTrackID } = this.props;
    const trackID = this.state.tracksList[this.state.currentTrackIndex].id;
    const trackURL = this.state.tracksList[this.state.currentTrackIndex].track;
    const trackTitle =
      this.state.tracksList[this.state.currentTrackIndex].title;
    const player = this.state.player;
    player.src = player.src !== trackURL ? trackURL : player.src;
    player.currentTime = 0;
    player.volume = this.state.volume;
    player.loop = this.state.loop;
    player.preload = true;
    // player.autoplay = autoplay;
    player.autoplay = false;
    this.setState(
      {
        // startPlaying: autoplay,
        trackID: trackID,
        trackURL: trackURL,
        trackTitle: trackTitle,
        isFavourite:
          this.state.tracksList[this.state.currentTrackIndex].isFavourite,
        player: player,
        trackLoaded: false,
        playing: autoplay,
        volume: player.volume,
      },
      () => {
        this.startPlaying();
        setPlaying(this.state.playing);
        if (!this.state.sidebar) {
          this.scrollToActiveTrack();
        }
        setCurrentTrackID(trackID);
      }
    );
  };

  assignEventsToPlayer = () => {
    this.state.player.addEventListener("canplay", () => {
      if (!this.state.trackLoaded) {
        console.log("Audio can start.");
      }
    });
    this.state.player.addEventListener("ended", () => {
      if (this.state.loop) {
        this.setState({ playing: true }, () => this.playPauseAudio());
      } else {
        this.playNextTrack();
      }
    });
    this.state.player.addEventListener("timeupdate", () => {
      if (this.state.trackLoaded) {
        this.updatePlayingInfo();
      }
    });
  };

  startPlaying = () => {
    this.setState(
      {
        trackLoaded: true,
      },
      () => {
        this.playPauseAudio();
        console.log("Ready to start playing.");
      }
    );
  };

  scrollToActiveTrack = () => {
    window.setTimeout(() => {
      const tracksListCtr = document.getElementById("tracksListCtr");
      const activeTrack = document.getElementById("activeTrack");
      tracksListCtr.scrollTo(
        0,
        activeTrack.offsetTop -
          parseFloat(window.getComputedStyle(tracksListCtr, null).height) / 2
      );
    }, 200);
  };

  expandPlayer = (event) => {
    this.setState({ expandedPlayer: true });
  };

  minPlayer = (event) => {
    this.setState({ expandedPlayer: false });
  };

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const newPlayer = new Audio();
      this.setState({ player: newPlayer }, () => {
        this.assignEventsToPlayer(false);
        this.loadTrackIntoState(false);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.state.player.pause();
    this.setState({
      isMounted: false,
      expandedPlayer: false,
      tracksList: playlist,
      currentTrackIndex: 0,
      trackID: null,
      trackURL: "",
      trackTitle: "",
      player: null,
      totalDuration: 0,
      currentTime: 0,
      progressSpanTime: 0,
      loop: false,
      shuffle: false,
      sidebar: false,
      playing: false,
      volume: 0.5,
      trackLoaded: false,
      progressHandleActive: false,
      progressHandleCtrStyleLeft: "0%",
      doneProgressBarStyleRight: "100%",
      volumeHandleActive: false,
      volumeHandleCtrStyleLeft: "0%",
      doneVolumeBarStyleRight: "100%",
      showfavsonly: false,
      searchString: "",
    });
  }

  render() {
    return (
      <div
        id="musicPlayer"
        className="Player container-fluid"
        onMouseEnter={this.expandPlayer}
        onMouseLeave={this.minPlayer}
      >
        <TracksList
          sidebar={this.state.sidebar}
          tracksList={this.state.tracksList}
          currentTrackIndex={this.state.currentTrackIndex}
          currentTrackID={this.state.trackID}
          favsonly={this.state.showfavsonly}
          searchString={this.state.searchString}
          updateSearchString={this.updateSearchString}
          toggleSidebar={this.toggleSidebar}
          toggleShowfavsonly={this.toggleShowfavsonly}
          selectThisTrack={this.selectThisTrack}
        />
        <div className="row">
          <div className="col-2 col-md-1 d-flex align-items-center">
            <button
              className={
                this.state.sidebar
                  ? "tracks-list-btn active"
                  : "tracks-list-btn"
              }
              onClick={this.toggleSidebar}
            >
              <img className="w-100" alt="Open track list" src={ListIcon} />
            </button>
          </div>
          <div className="col d-flex align-items-center">
            <p className="track-title mb-0">
              <span className="animated-track-title">
                {this.state.trackTitle}
              </span>
            </p>
            {/* <div className="ctr-1">
                            <p className="play-pause-text">
                                <strong>
                                    {this.state.playing ? "PLAYING" : "PAUSED"}
                                </strong>
                                <span>{this.state.currentTime} / {this.state.totalDuration}</span>
                            </p>
                        </div> */}
          </div>
          <div className="col">
            <div className="control-icons-container">
              <button
                className="play-control prev-track-btn-ctr"
                dangerouslySetInnerHTML={{
                  __html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 70.7 70.7" style="enable-background:new 0 0 70.7 70.7;" xml:space="preserve"><g><rect x="3.2" y="20.5" width="8.9" height="29.7"/><polygon points="59.4,54.3 23,35.3 59.4,16.3"/></g></svg>`,
                }}
                onClick={this.playPrevTrack}
              ></button>
              <button
                className="play-control play-pause-container"
                dangerouslySetInnerHTML={{
                  __html: this.state.playing
                    ? `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve"><g><rect x="15.7" y="9.2" width="19.2" height="61.7"/><rect x="45.2" y="9.2" width="19.2" height="61.7"/></g></svg>`
                    : `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve"><polygon points="17.5,70.8 76.5,40 17.5,9.2 "/></svg>`,
                }}
                onClick={this.onPlayPauseClickHandler}
              ></button>
              <button
                className="play-control next-track-btn-ctr"
                dangerouslySetInnerHTML={{
                  __html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 70.7 70.7" style="enable-background:new 0 0 70.7 70.7;" xml:space="preserve"><g><rect x="58.5" y="20.5" width="8.9" height="29.7"/><polygon points="11.3,54.3 47.7,35.3 11.3,16.3"/></g>`,
                }}
                onClick={this.playNextTrack}
              ></button>
            </div>
          </div>
          <div className="col-1">
            <button
              className={`d-box close-player-icon`}
              onClick={() => this.props.setShowPlayer()}
            >
              <img alt="Close player" src={CloseIcon} />
            </button>
          </div>
        </div>
        {this.state.expandedPlayer && (
          <div className="row mt-2">
            <div className="col">
              <div
                id="progress-bar"
                className="progress-container"
                onTouchStart={this.progBarPickHandler}
                onMouseDown={this.progBarPickHandler}
              >
                <div className="progress-bar">
                  <div
                    className="done"
                    id="doneProgressBar"
                    style={{
                      right: this.state.doneProgressBarStyleRight,
                    }}
                  ></div>
                  <div className="remaining"></div>
                </div>
                <div
                  className="handle-ctr"
                  id="progressHandleCtr"
                  style={{
                    left: this.state.progressHandleCtrStyleLeft,
                  }}
                >
                  {this.state.progressHandleActive && (
                    <p className="time-span">
                      {!this.state.progressHandleActive
                        ? this.state.currentTime
                        : this.state.progressSpanTime}
                      <span></span>
                    </p>
                  )}
                  <button
                    className={`handle ${
                      this.state.progressHandleActive ? "active" : ""
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: this.state.progressHandleActive
                        ? `
                                    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50%" cy="50%" r="4" />
                                    </svg>
                                  `
                        : `
                                    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50%" cy="50%" r="4" />
                                    </svg>
                                  `,
                    }}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="bottom-ctr">
          {/* <button
                        className={this.state.sidebar ? "tracks-list-btn active" : "tracks-list-btn"}
                        dangerouslySetInnerHTML={{__html:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 110" style="enable-background:new 0 0 225 110;" xml:space="preserve"><path d="M224.2,55c0,5-4,9-9,9H9.8c-5,0-9-4-9-9s4-9,9-9h205.3C220.1,46,224.2,50,224.2,55z M180.5,83.8H9.8c-5,0-9,4-9,9s4,9,9,9 h170.6c5,0,9-4,9-9S185.4,83.8,180.5,83.8z M9.8,26.2h135.7c5,0,9-4,9-9s-4-9-9-9H9.8c-5,0-9,4-9,9S4.9,26.2,9.8,26.2z"/></svg>`}}
                        onClick={this.toggleSidebar}
                    ></button> */}
          {/* <div className="bottom-icons-ctr">
                        <button
                            className={this.state.sidebar ? "tracks-list-btn active" : "tracks-list-btn"}
                            dangerouslySetInnerHTML={{__html:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 110" style="enable-background:new 0 0 225 110;" xml:space="preserve"><path d="M224.2,55c0,5-4,9-9,9H9.8c-5,0-9-4-9-9s4-9,9-9h205.3C220.1,46,224.2,50,224.2,55z M180.5,83.8H9.8c-5,0-9,4-9,9s4,9,9,9 h170.6c5,0,9-4,9-9S185.4,83.8,180.5,83.8z M9.8,26.2h135.7c5,0,9-4,9-9s-4-9-9-9H9.8c-5,0-9,4-9,9S4.9,26.2,9.8,26.2z"/></svg>`}}
                            onClick={this.toggleSidebar}
                        ></button>
                        <button
                            className={this.state.loop ? "loop-btn active" : "loop-btn"}
                            dangerouslySetInnerHTML={{__html:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 110" style="enable-background:new 0 0 225 110;" xml:space="preserve"><path d="M224.2,66.5c0,23.6-19.2,42.8-42.8,42.8H43.6C20,109.3,0.8,90.1,0.8,66.5S20,23.7,43.6,23.7h30c5,0,9,4,9,9s-4,9-9,9h-30 c-13.7,0-24.8,11.1-24.8,24.8S30,91.3,43.6,91.3h137.7c13.7,0,24.8-11.1,24.8-24.8S195,41.7,181.4,41.7h-39.9l8.1,8.1 c3.5,3.5,3.5,9.2,0,12.7c-1.8,1.8-4.1,2.6-6.4,2.6s-4.6-0.9-6.4-2.6L113.3,39c-3.5-3.5-3.5-9.2,0-12.7l22.9-22.9 c3.5-3.5,9.2-3.5,12.7,0c3.5,3.5,3.5,9.2,0,12.7l-7.6,7.6c0,0,0,0,0,0h40C205,23.7,224.2,42.9,224.2,66.5z"/></svg>`}}
                            onClick={this.toggleLoop}
                        ></button>
                        <button
                            className={this.state.shuffle ? "shuffle-btn active" : "shuffle-btn"}
                            dangerouslySetInnerHTML={{__html:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 110" style="enable-background:new 0 0 225 110;" xml:space="preserve"><path d="M224.2,32.6C224.2,32.7,224.2,32.7,224.2,32.6c0,0.3,0,0.5,0,0.8c0,0,0,0,0,0.1c-0.2,2.4-1.4,4.5-3.1,6l-23,23 c-1.8,1.8-4.1,2.6-6.4,2.6s-4.6-0.9-6.4-2.6c-3.5-3.5-3.5-9.2,0-12.7l8.1-8.1h-21.6c-11.1,0-21.9,3.1-31.4,8.9 c-1.5,0.9-3.1,1.3-4.7,1.3c-3,0-6-1.5-7.7-4.3c-2.6-4.2-1.3-9.8,3-12.4c12.3-7.5,26.4-11.5,40.8-11.5h21.7l-7.6-7.6 c-3.5-3.5-3.5-9.2,0-12.7c3.5-3.5,9.2-3.5,12.7,0l22.9,22.9C223.3,28,224.2,30.3,224.2,32.6z M215.2,91.3h-43.4 c-21.1,0-40.8-11.3-51.5-29.4c-13.9-23.6-39.6-38.2-67-38.2H9.8c-5,0-9,4-9,9s4,9,9,9h43.4c21.1,0,40.8,11.3,51.5,29.4 c13.9,23.6,39.6,38.2,67,38.2h43.4c5,0,9-4,9-9S220.1,91.3,215.2,91.3z M84.6,82.4c-9.4,5.8-20.3,8.9-31.3,8.9H9.8c-5,0-9,4-9,9 s4,9,9,9h43.4c14.4,0,28.5-4,40.8-11.5c4.2-2.6,5.6-8.1,3-12.4C94.4,81.1,88.8,79.8,84.6,82.4z"/></svg>`}}
                            onClick={this.toggleShuffle}
                        ></button>
                    </div> */}
        </div>
      </div>
    );
  }
}

export default Player;
