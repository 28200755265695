import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";

const storage = getStorage();

export const getMutlipleDownloadURLs = (route) => {
  const listRef = ref(storage, route);

  return listAll(listRef)
    .then((res) => {
      const promises = res.items.map((itemRef) => getDownloadURL(itemRef));
      return Promise.all(promises);
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.error("Sorry, we were not able to load the images.");
    });
};
