export const works = [
  {
    id: 1,
    trackID: [1, 2],
    title: "Escenas",
    instrumentation: "flute solo",
    year: 2010,
    duration: "5'",
    genre: "solo",
  },
  {
    id: 2,
    title: "Tres Poemas de Alejandra Pizarnik",
    trackID: [3],
    instrumentation: "soprano and piano",
    year: 2011,
    duration: "7'",
    genre: "voice",
  },
  {
    id: 3,
    title: "Temas y Variaciones",
    instrumentation: "flute, clarinet and bassoon",
    year: 2013,
    duration: "6'",
    genre: "chamber",
  },
  {
    id: 4,
    title: "Tres Poemas de Blanca Varela",
    instrumentation: "mixed choir",
    year: 2013,
    duration: "8'",
    genre: "choir",
  },
  {
    id: 5,
    title: "String Quartet Nº1",
    year: 2014,
    duration: "8'",
    genre: "chamber",
  },
  {
    id: 6,
    title: "De Rebus Humanis",
    instrumentation: "violin solo and small orchestra",
    year: 2015,
    duration: "12'",
    genre: "soloorch",
  },
  {
    id: 7,
    title: "Nuevas Escenas",
    trackID: [11, 12, 13],
    instrumentation: "full orchestra",
    year: 2017,
    duration: "15'",
    genre: "orch",
  },
  {
    id: 8,
    title: "Piano Trio Nº1",
    trackID: [4, 5, 6],
    instrumentation: "violin, cello and piano",
    year: 2018,
    duration: "13'",
    genre: "chamber",
  },
  {
    id: 9,
    title: "Dos Danzas",
    trackID: [7, 8],
    instrumentation: "piano",
    year: 2018,
    duration: "8'",
    genre: "solo",
  },
  {
    id: 10,
    title: "Soliloquia inter Memorias",
    trackID: [10],
    instrumentation: "cello solo",
    year: 2019,
    duration: "4'",
    genre: "solo",
  },
  {
    id: 11,
    title: "Dos Crisoles",
    trackID: [9],
    instrumentation: "flute and string orchestra (string quintet)",
    year: 2019,
    duration: "9'",
    genre: "soloorch",
  },
  {
    id: 12,
    title: "Tres Danzas",
    instrumentation: "orchestra (orchestration of Dos Danzas + 1)",
    year: 2019,
    duration: "15'",
    genre: "orch",
  },
  {
    id: 14,
    title: "Sonata",
    instrumentation: "flute and piano",
    year: 2020,
    duration: "",
    genre: "chamber",
  },
  {
    id: 15,
    title: "Ex Bosco, ad Arboretum",
    instrumentation: "string orchestra",
    year: 2020,
    duration: "",
    genre: "orch",
  },
  {
    id: 16,
    title: "Crepuscular",
    trackID: [15],
    instrumentation: "3 equal voices",
    year: 2020,
    duration: "3'",
    genre: "choir",
  },
  {
    id: 17,
    title: "Masa",
    trackID: [14],
    instrumentation: "mixed choir",
    year: 2021,
    duration: "4'",
    genre: "choir",
  },
];

export const playlist = [
  {
    id: 16,
    title: "Gigue, for guitar",
    track: require("../assets/works/catalog/guitarsuite/giga.mp3"),
  },
  {
    id: 14,
    title: "Masa, for mixed choir",
    track: require("../assets/works/catalog/Masa/Masa.mp3"),
  },
  {
    id: 4,
    title: "Piano Trio Nº1: I",
    track: require("../assets/works/catalog/pianotrio1/Piano Trio 1 - I.mp3"),
  },
  {
    id: 5,
    title: "Piano Trio Nº1: II",
    track: require("../assets/works/catalog/pianotrio1/Piano Trio 1 - II.mp3"),
  },
  {
    id: 6,
    title: "Piano Trio Nº1: III",
    track: require("../assets/works/catalog/pianotrio1/Piano Trio 1 - III.mp3"),
  },
  {
    id: 15,
    title: "Crepuscular, for equal voices",
    track: require("../assets/works/catalog/crepuscular/crepuscular.mp3"),
  },
  {
    id: 7,
    title: "Dos Danzas: Danza Costeña",
    track: require("../assets/works/catalog/dosdanzas/I. Danza costeña.mp3"),
  },
  {
    id: 8,
    title: "Dos Danzas: Danza Andina",
    track: require("../assets/works/catalog/dosdanzas/II. Danza andina.mp3"),
  },
  {
    id: 9,
    title: "Dos Crisoles",
    track: require("../assets/works/catalog/doscrisoles/2 crisoles (Sextet version).mp3"),
  },
  {
    id: 10,
    title: "Soliloquia inter memorias",
    track: require("../assets/works/catalog/soliloquium/soli2.mp3"),
  },
  {
    id: 11,
    title: "Nuevas Escenas: I",
    track: require("../assets/works/catalog/nuevasescenas/Nuevas Escenas I.mp3"),
  },
  {
    id: 12,
    title: "Nuevas Escenas: II",
    track: require("../assets/works/catalog/nuevasescenas/Nuevas Escenas II.mp3"),
  },
  {
    id: 13,
    title: "Nuevas Escenas: III",
    track: require("../assets/works/catalog/nuevasescenas/Nuevas Escenas III.mp3"),
  },
  {
    id: 3,
    title: "Tres Poemas de Alejandra Pizarnik (I, II, III)",
    track: require("../assets/works/catalog/pizarnik/Tres poemas Pizarnik - AliagaDelBosque.mp3"),
  },
  {
    id: 1,
    title: "Escenas: I",
    track: require("../assets/works/catalog/escenas/E1.mp3"),
  },
  {
    id: 2,
    title: "Escenas: II",
    track: require("../assets/works/catalog/escenas/E2.mp3"),
  },
];

export const upcoming = [
  {
    id: 1,
    title: `Taki 2021: Premiere of "Masa" `,
    date: `01/28/22`,
    type: "news",
    relatedId: "2021-taki-masa",
  },
  {
    id: 2,
    title: `Forbidden Clarity OST is out!`,
    type: "project",
    relatedId: "silva-000",
  },
  {
    id: 3,
    title: `Taki 2021: Premiere of "Crepuscular" `,
    date: `01/28/22`,
    type: "news",
    relatedId: "2020-taki-crepuscular",
  },
];

const data = {
  // news,
  works,
  playlist,
  // projects,
  upcoming,
};

export default data;
