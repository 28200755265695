import React from 'react';

import VerticalNavigation from './VerticalNavigation';
import styles from './sidebar.module.css';
import CloseIcon from '../../assets/icons/close.svg';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
    return (
        <>
            {sidebarOpen && <div className={`${styles['overlay']} ${sidebarOpen && styles['overlay-active']}`} onClick={() => setSidebarOpen(false)}></div>}
            <div className={`${styles['sidebar-wrapper']} ${sidebarOpen ? styles['active'] : styles['hidden']}`}>
                <div className="d-flex flex-row justify-content-end">
                    <button className={styles['close-btn']}>
                        <img alt="Close" src={CloseIcon} onClick={() => setSidebarOpen(!sidebarOpen)} />
                    </button>
                </div>
                <VerticalNavigation setSidebarOpen={setSidebarOpen}/>
            </div>
        </>
    );
};

export default Sidebar;