import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  Route,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  ref as databaseRef,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import db from "../../database";

import PageContainer from "../common/PageContainer";
import PageTitle from "../common/PageTitle";
import ProjectRender from "./ProjectRender";

import styles from "./projects.module.css";

const Projects = () => {
  const mounted = useRef();
  const { projectId } = useParams();
  let { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [loadingProject, setLoadingProject] = useState(false);

  const selectProject = (projectId) => {
    setLoadingProject(true);
    const selected = projects.find((project) => project.id === projectId);
    if (selected) {
      setSelectedProject(selected);
    }
    setTimeout(() => {
      setLoadingProject(false);
    }, 0);
  };

  const fetchProjects = async () => {
    const projectsRef = query(
      databaseRef(db, `projects`),
      orderByChild("published"),
      equalTo(true)
    );
    onValue(projectsRef, async (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        setProjects(data);
      } else {
        const isArray = Array.isArray(data);
        const dataArr = isArray ? data : Object.values(data);
        const filteredData = dataArr.filter((item) => !!item);
        // regex for id in filenames /^[^.]*/g
        setProjects(filteredData);
        if (projectId) {
          selectProject(projectId);
        } else {
          history.push(`/projects/${filteredData[0].id}`);
        }
      }
      setLoading(false);
      return data;
    });
  };

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      setLoadingProject(true);
      fetchProjects();
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      if (!projectId || !projects.some((project) => project.id === projectId)) {
        history.push(`/projects/${projects[0].id}`);
      }
      selectProject(projectId);
    }
    // eslint-disable-next-line
  }, [projectId, projects, selectedProject]);
  return (
    <PageContainer>
      <PageTitle title="Current projects &#38; al." />
      <div className="row">
        <div className="col-12 col-md-3">
          <ul className={styles["project-list"]}>
            {projects.map((project) => (
              <li
                key={`project-${project.label}`}
                className={`mb-2 ${styles["project-label"]} ${
                  project.label === selectedProject.label ? styles.selected : ""
                }`}
                onClick={() => history.push(`/projects/${project.id}`)}
              >
                {project.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 col-md-9 card border-0 px-0 ps-md-3 pe-md-0">
          <div className="row g-0">
            <Switch>
              <Route path={`${path}`}>
                <ProjectCard
                  loadingProject={loadingProject}
                  loading={loading}
                  projects={projects}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const ProjectCard = ({ loadingProject, loading, projects }) => {
  let { projectId } = useParams();
  const selectedProject = projects.find((item) => item.id === projectId);
  let imageExists = false;
  let imageModule = null;
  try {
    const m = require(`../../assets/${selectedProject.image}`);
    imageModule = m;
    imageExists = true;
  } catch (ex) {
    imageExists = false;
    return imageExists;
  }
  return !loading && selectedProject ? (
    <>
      {!loadingProject && selectedProject.image && !!imageModule && (
        <div className="col-12 p-0 d-flex d-md-none">
          <LazyLoadImage
            alt={selectedProject.label}
            src={imageModule}
            width={"100%"}
            effect="blur"
            wrapperClassName="d-flex"
            className={`card-img-top w-100 ${styles["project-img-top"]}`}
            placeholder={imageModule}
            visibleByDefault
            style={{
              objectPosition: `center ${
                !!selectedProject.imageVerticalPosition
                  ? selectedProject.imageVerticalPosition
                  : "top"
              }`,
            }}
          />
        </div>
      )}
      <div
        className={
          !loadingProject && selectedProject.image && !!imageModule
            ? "col-md-9"
            : "col-md-12"
        }
      >
        <div className="card-body">
          <div id="project-content">
            <ProjectRender
              title={selectedProject.title}
              content={selectedProject.content}
            />
          </div>
          {selectedProject.embedded && (
            <div className="row">
              {selectedProject.embedded.map((item, index) => (
                <div
                  className={`col-12 ${item.fullWidth ? "" : "col-md-6"} mb-4`}
                  key={`${item.title}-media-${index}`}
                >
                  <div className="row">
                    {item.title && (
                      <h4>
                        {item.title}{" "}
                        <a
                          href={item.titleLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className={`fas fa-external-link-alt ${styles["external-link"]}`}
                          ></i>
                        </a>
                      </h4>
                    )}
                    {item.image && (
                      <div className="col-12 col-sm-4 mb-3 mb-sm-0">
                        <LazyLoadImage
                          alt={item.title}
                          src={require(`../../assets/${item.image}`)}
                          width={"100%"}
                          effect="blur"
                          wrapperClassName="d-flex h-100"
                          className={styles["lazy-img"]}
                        />
                      </div>
                    )}
                    <div
                      className="col"
                      dangerouslySetInnerHTML={{ __html: item.media }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {!loadingProject && selectedProject.image && !!imageModule && (
        <div className="col-md-3 d-none d-md-flex">
          <LazyLoadImage
            alt={selectedProject.label}
            src={imageModule}
            width={"100%"}
            effect="blur"
            wrapperClassName="d-flex h-100"
            className={`${styles["lazy-img"]} img-fluid rounded-end`}
            visibleByDefault
            style={{
              objectPosition: `${
                !!selectedProject.imageHorizontalPosition
                  ? selectedProject.imageHorizontalPosition
                  : "center"
              } center`,
            }}
          />
        </div>
      )}
    </>
  ) : null;
};

export default Projects;
